.table_padding {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
}

* {
  margin: 0;
  padding: 0;
}
